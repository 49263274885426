import { colors } from './colors'

export const rem = (size: number) => size / 16

export const typography = {
  families: {
    headings: 'Inter',
    body: 'Inter',
  },
  headings: colors.default,
  text: colors.default,
  background: colors.lighter,
  border: colors.light,
  link: {
    default: colors.default,
    hover: colors.light,
  },

  dimensions: {
    fontSize: {
      small: 14,
      regular: 16,
      large: 18,
    },
    fontSizes: {
      root: rem(28),
      h1: rem(56),
      h2: rem(48),
      h3: rem(40),
      h4: rem(20),
      h5: rem(16),
      h6: rem(14),
      p: rem(16),
      li: rem(16),
    },
    lineHeight: {
      regular: 1.45,
      heading: 1.2,
      h1: rem(52),
      p: rem(32),
    },
  },

  weight: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
}
