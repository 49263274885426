
import { colors } from '@styles/colors'
import { rem } from '@styles/text'

export default `
  figure {
    figcaption {
      font-size: ${rem(13)}rem;
      line-height: 1.1;
      color: ${colors.light};
      font-style: italic;
    }
  }
}
`
