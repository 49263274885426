
import { colors } from '../colors'

export default `
.c-button {
  padding: 12px 24px;
  border-radius: 10px;
  white-space: nowrap;
      &:first-letter {
      text-transform: uppercase;

    }
}

.c-button_small {
    padding: 5px 10px;
    font-size: 0.8rem;
}

  .c-button_center {
    display: block;
    margin: 0 auto;
  }

  .c-button_primary {
    background: 0;
    color: ${colors.light};
    border: 1px solid #a8a4a4;
  }

  .c-button_secondary {
    background: ${colors.lighter};
    color: #a8a4a4;
    border: 1px solid #a8a4a4;
  }

`
