exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-tour-tsx": () => import("./../../../src/pages/book-tour.tsx" /* webpackChunkName: "component---src-pages-book-tour-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meettheartist-tsx": () => import("./../../../src/pages/meettheartist.tsx" /* webpackChunkName: "component---src-pages-meettheartist-tsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/news_index.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/news_post.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */),
  "component---src-templates-subpages-tsx": () => import("./../../../src/templates/subpages.tsx" /* webpackChunkName: "component---src-templates-subpages-tsx" */),
  "component---src-templates-works-index-tsx": () => import("./../../../src/templates/works_index.tsx" /* webpackChunkName: "component---src-templates-works-index-tsx" */),
  "component---src-templates-works-post-tsx": () => import("./../../../src/templates/works_post.tsx" /* webpackChunkName: "component---src-templates-works-post-tsx" */)
}

