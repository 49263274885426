export default `
  .underline {
    text-decoration: underline;
  }

  .underline-with-accent-color {
    border-bottom: 3px solid blue;
  }

  p > .space-large, a > .space-large  {
      display: inline-block;
      margin-bottom: 2rem ;
    }


`
