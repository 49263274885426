export default `
@keyframes draw-line {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.is-visible .u-text-decoration-underlined {
  &::after  {
    animation: draw-line  800ms ease-in 0s forwards;
  }
}

.u-text-decoration-underlined {
  display: inline-block;
  &::after  {
      content: '';
      margin-top: -19px;
      width: 0px;
      height: 10px;
      display: block;
      background: #B8412E20;
  }
}

.u-clickable {
  cursor: pointer;
}
`
