
import { breakpoint } from '../02-tools/tools.breakpoints'
import { typography } from '../text'

export default `
  html {
    background-color: ${typography.background};
    font-size: 0.8125rem;
    font-family: ${typography.families.body};
    color: ${typography.text};
    line-height: ${typography.dimensions.lineHeight.regular};
    min-height: 100%;
    overflow-y: scroll;
    // scroll-behavior: smooth;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    scroll-padding-top: 3rem;


  ${breakpoint.gt('md')`
      font-size: 0.875em;
      line-height: 1.5em;
  `}
  ${breakpoint.gt('lg')`
      font-size: 1em;
  `}
  }
`
