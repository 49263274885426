export enum Breakpoints {
  xSmall = 600,
  small = 768,
  medium = 992,
  large = 1280,
  xLarge = 1600,
  hd = 1920,
}

export const breakpoints = {
  xs: Breakpoints.xSmall,
  sm: Breakpoints.small,
  md: Breakpoints.medium,
  lg: Breakpoints.large,
  xl: Breakpoints.xLarge,
  hd: Breakpoints.hd,
}
