/**
 * SEO / Global Helmet component
 */

import React from 'react'
import { Helmet } from 'react-helmet'

import { GenerateLinksPreconnect } from './links-preconnect'

export const GlobalHelmet = () => {
  return (
    <Helmet>
      {GenerateLinksPreconnect()}
    </Helmet>
  )
}
