import generic from './03-generic/'
import elements from './04-elements/'
import components from './08-components/'
import utilities from './09-utilities/'
import { createGlobalStyle } from 'styled-components'
import { rem } from './text'
import { breakpoint } from './02-tools/tools.breakpoints'

export const GlobalStyles = createGlobalStyle`
  ${generic}
  ${elements}
  ${components}
  ${utilities}

  .book-form-tally {
    margin-top: 124px;
  }

    strong {
    font-weight: bolder;
  }

.customHtmlElement {
  #mc_embed_signup {
    width: revert;
  }
}


.homep-text2 {
  font-size: ${rem(61)}rem;
  span, div {
    display: inline-block;
  }
  span {
    color: #B8412E;
    margin: 0 0.1em;
    ${breakpoint.gt('md')`
    margin: 0 0.33em;
  `}
  }
    .second-line {
        ${breakpoint.gt('md')`
      transform: translateX(15rem);
  `}

      div:last-of-type div {
          span:last-of-type {
                ${breakpoint.gt('md')`
        margin: 0;
  `}
  }
      }
    }

}


.c-faq {

  h2 {
        margin-bottom: 1rem;

  }

  h4 {
    margin-bottom: 1rem;
  }
}
`
