import { bpDown, breakpoint } from '../02-tools/tools.breakpoints'

export default `
.c-content-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.c-content-video__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-fullscreen-video {
  background: #0F0F0F;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
}

.c-fullscreen-video__foreground,
.c-fullscreen-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  ${breakpoint.lt('md')`
    height: 200%;
    top: -50%;
    width: 200%;
    left: -50%;
  `}
}

.c-fullscreen-video__go-forward {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
}

@media (min-aspect-ratio: 16/9) {
  .c-fullscreen-video__foreground {
    border: 23px solid green;
    height: 200%;
    top: -50%;
    width: 200%;
    left: -50%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .c-fullscreen-video__foreground {
    border: 23px solid red;
    width: 300%;
    left: -100%;
    height: 120%;
    top: -10%;
  }
}
`
