
import { colors } from '../colors'

export default `
  .masonry-grid {
    border: 2px solid red;



  }

`
