import { breakpoints as Breakpoints } from '@styles/01-settings/settings.breakpoints'
import { css } from 'styled-components'

// prettier-ignore
export const bp = (size: number, content: string) => `@media (min-width: ${size}px) { ${content} }`

// prettier-ignore
export const bpDown = (size: number, content: string) => `@media (max-width: ${size}px) { ${content} }`

// prettier-ignore
export const bpBetween = (
  minSize: number,
  maxSize: number,
  content: string,
) => `@media (min-width: ${minSize}px) and (max-width: ${maxSize - 0.02}px) { ${content} }`

const breakpoints: { [index: string]: number } = Breakpoints
type TBreakpoint = keyof typeof Breakpoints

/**
 * better solution for set breakpoints
 * @param breakpointKey "xs" | "sm" | "md" | "lg" | "xl" | "hd"
 *
 */
function getSizeFromBreakpoint(breakpointKey: TBreakpoint): number {
  if (breakpoints[breakpointKey]) {
    return breakpoints[breakpointKey]
  } else if (parseInt(breakpointKey, 10)) {
    return parseInt(breakpointKey, 10)
  } else {
    throw Error('No valid breakpoint or size specified for media.')
  }
}

// prettier-ignore
const lt = (breakpointKey: TBreakpoint) => (
  literals: TemplateStringsArray,
  ...placeholders: any[]
) =>
  css`
    @media (max-width: ${getSizeFromBreakpoint(breakpointKey)}px) {
      ${css(literals, ...placeholders)}
    }`.join('')

// prettier-ignore
const gt = (breakpointKey: TBreakpoint) => (
  literals: TemplateStringsArray,
  ...placeholders: any[]
) =>
  css`
    @media (min-width: ${getSizeFromBreakpoint(breakpointKey)}px) {
      ${css(literals, ...placeholders)}
    }`.join('')

// prettier-ignore
const bt = (
  firstBreakpointKey: TBreakpoint,
  secondBreakpointKey: TBreakpoint,
) => (literals: TemplateStringsArray, ...placeholders: any[]) =>
  css`
    @media (min-width: ${getSizeFromBreakpoint(firstBreakpointKey)}px) and (max-width: ${getSizeFromBreakpoint(secondBreakpointKey) - 0.02}px) {
      ${css(literals, ...placeholders)}
    }`.join('')

export const breakpoint = {
  lt,
  gt,
  bt,
}
