import React, { ReactNode } from 'react'

import { GlobalStyles } from '@styles/index'
import { GlobalHelmet } from '@components/global-helmet'



type IndexProps = {
  children: ReactNode
}

const IndexLayout = ({ children }: IndexProps) => {
  return (
    <>
      <GlobalHelmet />
      <GlobalStyles />
      {children}
    </>
  )
}

export { IndexLayout }
